import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';


const RadialSemiGaugeChart = ({ avgRadialChartData, title, color, isPercent = false }: any) => {
    
    const options: ApexOptions = {
        series: [avgRadialChartData],
        chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#e7e7e7",
                    strokeWidth: '100 %',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                    }
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: isPercent ? {
                        offsetY: -2,
                        fontSize: '22px'
                    } : {
                        offsetY: -2,
                        fontSize: '22px',
                        formatter: (val: number) => `${val}` // 
                    }
                }
            }
        },
        grid: {
            padding: {
                top: -10
            }
        },
        fill: {
            type: 'flat',
            colors: [color]
        },
        title: {
            text: `${title}`,
            align: 'center',
            margin: 10,
            offsetY: 0,
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#263238'
            }
        }
    };

    return (
        <div className="radial-chart">
            <Chart options={options} series={options.series} type="radialBar" height={390} />
        </div>
    );
};

export default RadialSemiGaugeChart;

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Store/store';
import { getDashboardDataHandler } from '../Store/slices/videoSlice';
import Header from '../../components/Header';
import BarChart from '../../components/BarChart';
import VideoScoreRatingChart from '../../components/VideoScoreRatingChart';
import BrandVideoScoreRatingChart from '../../components/BrandVideoRatingChart';
import RadialSemiGaugeChart from '../../components/RadialChart';
import { userBrandHandler, userDealerHandler, userFirstLoginHandler, userSelectedBrandHandler, userSelectedDealerHandler } from '../Store/slices/authSlice';
import { dealersHandler, getAllBrandsHandler, getAllDealersHandler, getAllUserBrandsHandler, getUserDealersHandler } from '../Store/slices/dealerSlice';
import ExcelJS from 'exceljs';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { user, isFirstLogin, isAdmin, selectedBrandId, selectedDealerId } = useSelector((state: RootState) => state.auth);
    const user_id = user?.user?.uuid ?? user?.uuid;

    const [chartData, setChartData] = useState<any>({});
    const [ratingPercent, setRatingPercent] = useState<any>([]);
    const [brandVideoRatingPercent, setBrandVideoRatingPercent] = useState<any>([]);
    const [dealerVideosStatusPercent, setDealerVideosStatusPercent] = useState<any>([]);
    const [trigger, setTrigger] = useState(false);
    const [allVideosCount, setAllVideosCount] = useState<number>(0);
    const chartRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if (isAdmin) {
        navigate('/admin/dashboard')
        } else {
            dispatch(getAllUserBrandsHandler(user_id)).then((res: any) => {
                if (res?.payload?.statusCode === 200) {
                    const brandUuid = selectedBrandId || res.payload?.data[0]?.uuid;

                    const brands = res.payload.data.map((brand: any) => ({
                        value: brand.uuid,
                        label: brand.name
                    }));
                    dispatch(userBrandHandler(brands));
                    dispatch(userSelectedBrandHandler(brandUuid))
                    const dealerData = {
                        userId: user_id,
                        brand: brandUuid
                    }
                    dispatch(getUserDealersHandler(dealerData)).then((res: any) => {
                        if (res?.payload?.statusCode === 200) {

                            const dealers = res.payload.data.map((dealer: any) => ({
                                value: dealer.uuid,
                                label: dealer.name
                            }));

                            const dealerUuid = selectedDealerId || res.payload?.data[0]?.uuid;
                            dispatch(userDealerHandler(dealers));
                            dispatch(userSelectedDealerHandler(dealerUuid))
                            setTimeout(() => {
                                dispatch(userFirstLoginHandler())
                            }, 3000)
                            dashboardPercentData(brandUuid, dealerUuid)
                        }
                    });
                }
            });
        }

    }, []);


    useEffect(() => {
        const brandName = selectedBrandId ?? '0';
        const dealer = selectedDealerId ?? '0';
        dashboardPercentData(brandName, dealer)
    }, [trigger])

    const dashboardPercentData = (brandName: string, dealer: string) => {
        const data = {
            userid: user_id,
            brand: brandName,
            dealerUuid: dealer
        }

        if (brandName != '0') {
            dispatch(getDashboardDataHandler(data)).then((dashboardData:any)=>{
                if (dashboardData.payload) {
                    setChartData(dashboardData.payload.customObjectData);
                    setRatingPercent(dashboardData.payload.videoScoreRatingByDealer);
                    setBrandVideoRatingPercent(dashboardData.payload.videoScoreRatingByBrand);
                    setDealerVideosStatusPercent(dashboardData.payload.videosStatus);
                    setAllVideosCount(dashboardData.payload.videosStatus?.allVideosCount)
                }
            })
        }
      
    }

    const setTriggerfn = (data: any) => {
        setTrigger(true);
        setTimeout(() => {
            setTrigger(false);
        }, 500);
    }    
    const getVideoObjectChart =async(data:any)=>{
        if(data){
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet('Bar Chart');
    
            // Generate chart image using html2canvas
            const chartElement = chartRef.current as HTMLElement;
            if (chartElement) {
                // Ensure the chart is fully rendered
                await new Promise(resolve => setTimeout(resolve, 1000));
    
                // Use html2canvas to capture the chart as an image
                const canvas = await html2canvas(chartElement, { scale: 2 });
    
                // Convert canvas to base64 image
                const image = canvas.toDataURL('image/png');
    
                // Add the chart image to the sheet
                const imageId = workbook.addImage({
                    base64: image.split(',')[1],
                    extension: 'png',
                });
    
                // Increase the size of the image in Excel
                const imageSize = {
                    width: canvas.width / 2,
                    height: canvas.height / 2
                };
    
                sheet.addImage(imageId, {
                    tl: { col: 1, row: 1 },
                    ext: imageSize,
                });
            }
    
            // Save workbook
            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), 'bar_chart.xlsx');
        }
    }

    return (
        <div className="min-vh-100 container-fluid">
            <div className='px-lg-5 mx-lg-5'>
                <div className="mt-5 mb-4 pb-2">
                    <Header setTrigger={setTriggerfn} videoObject = {getVideoObjectChart} />
                </div>

                <div className="row gy-4 mb-4">
                    <div className="col-lg-2">
                        <div className="dashboard-card all-number mt-5">
                            <RadialSemiGaugeChart avgRadialChartData={dealerVideosStatusPercent?.successPercent || 0} title={'Success Rate'} color={'#28a745'} isPercent={true} />
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="box mt-4">
                            <div className="mt-5">
                                <div id="progress2"></div>
                                <RadialSemiGaugeChart avgRadialChartData={dealerVideosStatusPercent?.failedPercent || 0} title={'Failed Rate'} color={'#dc3545'} isPercent={true} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="box mt-4">
                            <div className="mt-5">
                                <div id="progress3"></div>
                                <RadialSemiGaugeChart avgRadialChartData={dealerVideosStatusPercent?.noisyVideoPercent || 0} title={'Noisy Rate'} color={'#fd7e14'} isPercent={true} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 total-videos">
                        {/* use border-${here} | danger | warning | info | success; classes below*/}
                        <div className={`card dashboard-card all-number border-info`}>
                            <div className="card-body d-flex flex-column align-items-center justify-content-center gap-3">
                                <h5 className="card-title mb-0 text-truncate" title='Average words per minute'>Average WPM</h5>
                                <div className="fs-1 lh-sm">{dealerVideosStatusPercent?.avgWordsPerMinute || 0}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 total-videos">
                        <div className={`card dashboard-card all-number border-warning`}>
                            <div className="card-body d-flex flex-column align-items-center justify-content-center gap-3">
                                <h5 className="card-title mb-0 text-truncate">Average video length</h5>
                                <div className="fs-1 lh-sm">{dealerVideosStatusPercent?.avgVideoLength || 0}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 total-videos">
                        <div className={`card dashboard-card all-number border-success`}>
                            <div className="card-body d-flex flex-column align-items-center justify-content-center gap-3">
                                <h5 className="card-title mb-0 text-truncate">Total Videos</h5>
                                <div className="fs-1 lh-sm">{allVideosCount}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-lg-12 mb-3">
                        <div className="dashboard-card all-number">
                            <BarChart brandsVideoObjectsPercent={chartData} chartRef={chartRef} />
                        </div>
                    </div>
                </div>
                {/*commented for future use*/}
                <div className="row">
                    <div className="col-lg-6">
                        <div className="dashboard-card all-number mt-5">
                            <VideoScoreRatingChart brandsVideoRatingPercent={ratingPercent} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="dashboard-card all-number mt-5">
                            <BrandVideoScoreRatingChart brandsVideoRatingPercent={brandVideoRatingPercent} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

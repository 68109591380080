import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '../pages/Store/store';
import { logoutHandler, userDealerHandler, userHandler, userSelectedBrandHandler, userSelectedDealerHandler } from '../pages/Store/slices/authSlice';
import { getUserDealersHandler } from '../pages/Store/slices/dealerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faUser } from '@fortawesome/free-solid-svg-icons';
import Logo from "../assets/images/logo.png";
import { Container, Nav, Navbar } from 'react-bootstrap';
import * as routeConstant from "../common/routeConstants";
import { MenuItem, TextField } from '@mui/material';
import { Pair } from '../types/user.type';
import { getBrandTotalVideoAvgLengthHandler, getBrandTotalVideoCountHandler, getBrandTotalVideoFailureRateHandler, getBrandTotalVideoNoisyRateHandler, getBrandTotalVideoScoreByBrandDealerHandler, getBrandTotalVideoScoreByBrandHandler, getBrandTotalVideoSuccessRateHandler } from '../pages/Store/slices/videoSlice';
import { exportToExcelFile } from '../services/exportToexcel';

const Header = ({ setTrigger, videoObject }: any) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { isAdmin, user, admin, userBrands, userDealers, selectedBrandId, selectedDealerId } = useSelector((state: RootState) => state.auth);
  const token = user?.token || admin?.token;
  const user_email = isAdmin ? admin?.user?.fName : user?.user?.fName ?? user?.email;
  const [selectedBrandUuid, setSelectedBrandUuid] = useState<any>('');
  const [selectedDealerUuid, setSelectedDealerUuid] = useState<any>('');
  const [brandOptions, setBrandsOptions] = useState<any>([]);
  const [dealerOptions, setDealerOptions] = useState<any>([]);

  const userId = user?.user?.uuid ?? user?.uuid;
  useEffect(() => {
    setBrandsOptions(userBrands)
    setDealerOptions(userDealers)
    const brandUuid = selectedBrandId || userBrands[0]?.value;
    setSelectedBrandUuid(brandUuid);
    const dealerUuid = selectedDealerId || userDealers[0]?.value;
    setSelectedDealerUuid(dealerUuid);
  }, [userBrands, userDealers]);

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    }
  }, [token, navigate]);

  const handleLogout = () => {
    dispatch(logoutHandler());
    dispatch(userHandler(false));
    navigate("/");
  };

  const handleBrandChange = async (e: any) => {
    const brandUuid = e.target.value
    dispatch(userSelectedBrandHandler(brandUuid))
    setSelectedBrandUuid(brandUuid);

    const dealerData = {
      userId,
      brand: brandUuid
    }
    await dispatch(getUserDealersHandler(dealerData)).then((res: any) => {
      const dealers = res.payload.data.map((dealer: any) => ({
        value: dealer.uuid,
        label: dealer.name
      }));
      setDealerOptions(dealers)

      const dealerUuid = res.payload.data[0]?.uuid;
      dispatch(userDealerHandler(dealers))
      dispatch(userSelectedDealerHandler(dealerUuid))
      setSelectedDealerUuid(dealerUuid);
      setTrigger(true);
    });
  };

  const handleDealerChange = async (e: any) => {
    const dealerUuid = e.target.value
    dispatch(userSelectedDealerHandler(dealerUuid))
    setSelectedDealerUuid(dealerUuid);
    setTrigger(true);
  };
  const reportsOptions = [
    "Dealer wise Video Object",
    "Total Videos brand wise",
    "Dealer wise average Video length",
    "Dealer wise noisy rate",
    "Dealer wise failure rate",
    "Dealer wise success rate",
    "Dealer Video Score for each Brand",
    "Brand wise video score",
  ]
  const exportToExcel = (reportOption: any) => {
    switch (reportOption) {
      case "Dealer wise Video Object":
        videoObject(reportOption)
        break;
      case "Total Videos brand wise":
        dispatch(getBrandTotalVideoCountHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        break;
      case "Dealer wise average Video length":
        dispatch(getBrandTotalVideoAvgLengthHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        break;
      case "Dealer wise noisy rate":

        dispatch(getBrandTotalVideoNoisyRateHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        break;
      case "Dealer wise failure rate":
        dispatch(getBrandTotalVideoFailureRateHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        break;
      case "Dealer wise success rate":
        dispatch(getBrandTotalVideoSuccessRateHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        break;
      case "Dealer Video Score for each Brand":

        dispatch(getBrandTotalVideoScoreByBrandHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        console.log("Exporting Dealer video score report");
        break;
      case "Brand wise video score":
        dispatch(getBrandTotalVideoScoreByBrandDealerHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        // console.log("Exporting Brand wise video score report");
        break;
      default:
        console.log("Unknown report option");
    }
  };
  return (
    <Navbar expand="lg" className="header">
      <Container fluid className="px-0">
        <Link to={!isAdmin ? routeConstant.DASHBOARD : routeConstant.ADMIN_DASHBOARD} className="navbar-brand">
          <img src={Logo} height={48} alt="logo" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="flex-fill mt-3 mt-lg-0 mb-3 mb-lg-0 flex-wrap gap-3">
            <div className="flex-fill d-flex flex-column flex-lg-row justify-content-lg-end gap-2 gap-lg-4">
              {!isAdmin &&
                <>
                  <Link to={routeConstant.DASHBOARD} className="nav-link">
                    DASHBOARD
                  </Link>
                  <Link to={routeConstant.VIDEOS} className="nav-link">
                    VIDEOS
                  </Link>
                  <div className="dropdown show nav-link">
                    <a className="nav-link p-0 flex-row align-items-center gap-2 justify-content-start justify-content-lg-start" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span>REPORTS</span> <FontAwesomeIcon icon={faCaretDown} />
                    </a>
                    <div className="dropdown-menu">
                      {reportsOptions.map((option: any) => {
                        return (
                          <>
                            <div className="dropdown-item" onClick={() => exportToExcel(option)}>{option}</div>
                          </>
                        )

                      })}
                    </div>
                  </div>
                </>
              }
            </div>
            <div className="d-flex flex-column flex-lg-row justify-content-lg-end gap-4 gap-lg-3 mt-3 mt-lg-0 flex-grow-1">
              {!isAdmin &&
                <>
                  <TextField
                    select
                    label="Select Brand"
                    fullWidth
                    variant="outlined"
                    value={selectedBrandId}
                    onChange={handleBrandChange}
                    className='nav-link dealers-dropdown'
                  >
                    {brandOptions?.map((option: Pair, index: number) => (
                      <MenuItem key={option.value + index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    label="Select Dealers"
                    fullWidth
                    variant="outlined"
                    value={selectedDealerId}
                    onChange={handleDealerChange}
                    className='nav-link dealers-dropdown'
                  >
                    {dealerOptions?.map((option: Pair, index: number) => (
                      <MenuItem key={option.value + index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              }

              <div className='d-flex d-lg-none justify-content-between gap-3'>
                <div className='hstack gap-3'>
                  <FontAwesomeIcon icon={faUser} className="fs-2" />
                  <div className="text-black">Hello, {user_email}</div>
                </div>
                <button className="btn btn-primary" onClick={handleLogout}>
                  Logout
                </button>
              </div>
              <div className="dropstart custom-dropdown me-lg-3 align-self-center d-none d-lg-flex">
                <button
                  className="btn p-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faUser} className="fs-2" />
                </button>
                <div className="dropdown-menu">
                  <div className="custom-dropdown-menu">
                    <div className="text-black">Hello, {user_email}</div>
                    <button className="btn btn-primary" onClick={handleLogout}>
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
import * as XLSX from 'xlsx';

// Utility function to convert camelCase or snake_case to Title Case
const formatHeader = (header: string): string => {
    return header.replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
                 .replace(/_/g, ' ') // Replace underscores with spaces
                 .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()); // Capitalize the first letter of each word
};

export const exportToExcelFile = async (data: any[], reportOption: string) => {
    if (data.length === 0) return;

    if (Array.isArray(data[0]?.dealers)) {
        const wb = XLSX.utils.book_new();

        data.forEach((item: any) => {
            if (item.dealers.length === 0) return;

            // Get headers dynamically
            const headers = Object.keys(item.dealers[0]);
            const formattedHeaders = headers.reduce((acc: { [key: string]: string }, key: string) => {
                acc[key] = formatHeader(key);
                return acc;
            }, {});

            // Map data with formatted headers
            const wsData = item.dealers.map((dealer: any) => {
                const formattedDealer: { [key: string]: any } = {};
                headers.forEach((header: string) => {
                    formattedDealer[formattedHeaders[header]] = dealer[header];
                });
                return formattedDealer;
            });

            const ws = XLSX.utils.json_to_sheet(wsData);
            XLSX.utils.book_append_sheet(wb, ws, item.brand);
        });

        // Generate Excel file and trigger download
        XLSX.writeFile(wb, `${reportOption}.xlsx`);
    } else {
        // Get the keys from the first item in the data array to use as column headings
        const headings = Object.keys(data[0]);

        // Format the data to match the dynamic headings
        const formattedData = data.map((item: any) => {
            const formattedItem: { [key: string]: any } = {};
            headings.forEach((heading: string) => {
                formattedItem[formatHeader(heading)] = item[heading];
            });
            return formattedItem;
        });

        // Convert formatted data to worksheet and workbook
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Report");

        // Generate Excel file and trigger download
        XLSX.writeFile(wb, `${reportOption}.xlsx`);
    }
};
